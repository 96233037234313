import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { configure } from 'axios-hooks';
import Axios from 'axios';

export const axios = Axios.create({
  withCredentials: true,
});


const url = new URL(window.location.href);
const queryParams = new URLSearchParams(url.hash.split('?')[1]);
const myParam = queryParams.get('token');
const hasMyParam = queryParams.has('token');
let token:any;
if(hasMyParam && myParam!=''){
    localStorage.clear();
    token  = myParam;
}else{
    token = localStorage.getItem('token');
}

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    if (token) {
      const apiurl : string | undefined = config.url;
      // Use a regular expression to remove the parameter
      const updatedQueryString = apiurl?.split('?')[1]?.split('&').filter(param => !param.startsWith('apiKey=')).join('&');
      config.url = updatedQueryString ?  apiurl?.split('?')[0] + '?' + updatedQueryString : apiurl?.split('?')[0];
      config.headers.Authorization = `Bearer ${token}`;
      
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


configure({ axios });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
